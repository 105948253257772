import { ordersConstants } from '../constants';
import { userConstants } from "../../auth/constants";

const initialState = { orders: [], allOrders: {orders: [], report: null, totalOrders: 0}, order: {}, quantities: [] };

export function orders(state = initialState, { type, payload, error }) {
  switch (type) {
    case ordersConstants.REQUEST_GET_ORDERS_FINISHED:
      return {
        ...state,
        orders: payload && payload.data,
      };
    case ordersConstants.REQUEST_GET_ALL_ORDERS_FINISHED:
      return {
        ...state,
        allOrders: payload && payload.data,
      };
    case ordersConstants.REQUEST_GET_ALL_QUANTITIES_FINISHED:
      return {
        ...state,
        quantities: payload && payload.data
      }
    case ordersConstants.REQUEST_GET_SINGLE_ORDER_FINISHED:
      return {
        ...state,
        order: payload && payload.data,
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

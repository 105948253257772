import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...routeProps }) => {
  const authenticated = useSelector(state => state.authentication.authenticated);

  return (
    <Route {...routeProps} render={props =>
      authenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    } />
  );
};

export default PrivateRoute;
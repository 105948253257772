import { requesting } from './requesting.reducer';
import { alert } from './alert.reducer';
import { modules } from './modules.reducer';
import { companies } from './companies.reducer';
import { images } from './images.reducer';

export default {
  requesting,
  alert,
  modules,
  companies,
  images
};
import { modulesConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = {
  rootModule: {}
};

export const modules = (state = initialState, { type, payload }) => {
  switch (type) {
    case modulesConstants.SET_ROOT_MODULE:
      return {
        ...state,
        rootModule: payload,
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
};
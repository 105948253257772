import React from 'react';
import Switch from '@material-ui/core/Switch';
import './SwitchKolo.scss'

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStylesKolo = makeStyles((theme) => ({
  kolo: {
    color: 'white',
  },
}));

function KoloCircularProgress(props) {
  const classes = useStylesKolo();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.kolo}
        size={22}
      />
    </div>
  );
}

const SwitchKolo = ({
                      name,
                      checked,
                      onChange,
                      disabled,
                      loading
                    }) => {

  const KoloSwitch = withStyles((theme) => ({
    root: {
      width: 45,
      height: 24,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 2,
      margin: 0,
    },
    switchBase: {
      paddingLeft: 2,
      paddingTop: 2,
      '&$checked': {
        transform: 'translateX(21px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#B35796',
          opacity: 1,
          border: '1px solid #B35796',
        },
      },
      '&$focusVisible $thumb': {
        color: '#B35796',
        border: '6px solid #B35796',
      },
    },
    thumb: {
      width: 20,
      height: 20,
      backgroundColor: '#FFFFFF',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #AAADB3`,
      backgroundColor: '#AAADB3',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({classes, ...props}) => {
    return (
      <div className="switch-container">
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          className="wo-back"
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
        {
          props.loading &&
          <div className="switch-loading">
            <KoloCircularProgress/>
          </div>
        }
      </div>
    );
  });

  return (
    <KoloSwitch  checked={checked}
                 onChange={onChange}
                 loading={loading}
                 disabled={disabled}
                 name={name}/>
  );
};

export default SwitchKolo;

import React, {useEffect, useState} from 'react';
import "./ImageKolo.scss";
import Box from "@material-ui/core/Box";
import AddImage from '../../../../assets/add-image.svg'
import IconDelete from '../../../../assets/icon-delete.svg'
import {useDispatch, useSelector} from "react-redux";
import { imagesActions } from '../../actions';
import { FilePicker } from 'react-file-picker';
import {selectRequesting} from "../../selectors";
import {productConstants} from "../../../product/constants";
import {imagesConstants} from "../../constants";

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStylesKolo = makeStyles((theme) => ({
  kolo: {
    color: 'white',
  },
}));

function KoloCircularProgress(props) {
  const classes = useStylesKolo();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.kolo}
        size={22}
      />
    </div>
  );
}

const ImageKolo = ({
                     image,
                     disabled,
                     className,
                     classNameImage,
                     imagesType,
                     parentId,
                     companyId
                   }) => {

  const dispatch = useDispatch();

  const [action, setAction] = useState({
    actionImage: false, type: {}, id: 0
  });

  const [progress, setProgress] = useState(0)

  const onUpload = (file) => {
    setAction({...action, type: imagesType, id: image.id})
    const onProgress = progress => setProgress(progress)
    const onSuccess = () => setProgress(0);
    dispatch(imagesActions.uploadImages(file, imagesType, parentId, onProgress, onSuccess))
  };

  useEffect(() => {
    console.log(progress)
  }, [progress])

  const onDelete = (image) => {
    setAction({...action, type: imagesType, id: image.id})
    const onProgress = progress => setProgress(25)
    const onSuccess = () => setProgress(0);
    dispatch(imagesActions.deleteImages(image.id, image.name, imagesType, parentId, onProgress, onSuccess))
  };

  const isLoading = useSelector(state => selectRequesting(state, [imagesConstants.REQUEST_UPLOAD_IMAGES, imagesConstants.REQUEST_DELETE_IMAGES]))

  useEffect(() => {
    if(isLoading) setAction({...action, actionImage: true})
    else if(action.actionImage && action.type === imagesType){
      if(imagesType.id === 7) dispatch(imagesActions.getLogo(imagesType, parentId, companyId))
      else if(imagesType.id === 15) dispatch(imagesActions.getHistories(imagesType, parentId, companyId))
      else dispatch(imagesActions.getImages(imagesType, parentId, companyId))
      setAction({...action, actionImage: false, type: {}})
    }
  }, [isLoading])

  return (
    <div
      className={`img-kolo-card no-select ${image.type === 'add' ? 'img-kolo-add' : ''} ${className} ${image.type === 'add' && !disabled ? 'ikc-not-disabled' : ''}`}>
      {
        image.type === "add" ?
          !disabled ?
            <FilePicker
              maxSize={5}
              extensions={['jpeg', 'jpg', 'png', 'svg']}
              onChange={onUpload}
              onError={(error) => console.log(error)}>
              <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                <img className="" src={AddImage} alt={"+"}/>
                <div className="img-kolo-text">agregar imagen</div>
              </Box>
            </FilePicker>
            :
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
              <img className="" src={AddImage} alt={"+"}/>
              <div className="img-kolo-text">agregar imagen</div>
            </Box>
          :
          <div className="img-kolo-container">
            <img className={`img-kolo ${classNameImage}`} src={image.url} alt={""}/>
            {!disabled &&
            <div className="ik-delete a-in-opacity" onClick={() => {onDelete(image)}}>
              <img src={IconDelete} alt={"-"}/>
            </div>
            }
          </div>
      }
      {
        (isLoading || progress > 0) && action.id === image.id &&
        <div className="img-kolo-loading">
          <KoloCircularProgress/>
        </div>
      }
    </div>
  )
};

export default ImageKolo;

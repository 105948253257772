import { dashboardConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = {
  currentCompany: {},
};

export function dashboard(state = initialState, { type, payload, error }) {
  switch (type) {
    case dashboardConstants.REQUEST_CURRENT_COMPANY_FINISHED:
      return {
        ...state,
        currentCompany: payload && payload.data || {}
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

import React from 'react';
import './ModalKolo.scss';
import { makeStyles } from '@material-ui/core/styles';
import {Backdrop, Fade, Box, Modal, useMediaQuery} from '@material-ui/core';
import { Button, CardKolo } from "../index";
import IconClose from "../../../../assets/icon-close.svg";

import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContent:{
    outline: 0,
    minWidth: 440,
    maxWidth: 685
  },
  modalContentMobile:{
    outline: 0,
    backgroundColor: '#FFFFFF'
  }
}));

const ModalKolo = ({
                     title,
                     children,
                     onClose,
                     open,
                     onAccept,
                     onCancel,
                     showCancelButton,
                     cancelButtonText,
                     confirmButtonText,
                     cancelButtonType,
                     hideActions,
                     centerActions,
                     disabledConfirm,
                     loading,
                     loadingCancel
                   }) => {

  const classes = useStyles();

  const isPhone = useMediaQuery('(max-width:480px)');

  const handleOnAccept = () => {
    if (onAccept) onAccept();
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 400,
      }}
    >
      <Fade in={open} timeout={400}>

        {
          !isPhone ?
            <Box className={classes.modalContent}>
              <CardKolo title={title} buttons={<img
                className="modal-close"
                alt=""
                src={IconClose}
                onClick={onClose}
                white
              />}>
                <Box className="kolo-border-bottom">{children}</Box>
                  <Box display={"flex"} justifyContent={`${centerActions ? 'center': 'flex-end'}`} width={1}>
                    {
                      !hideActions &&
                    <Box mx={2} display={"flex"} flexDirection={"row"}>
                      {
                        showCancelButton &&
                        <Button loading={loadingCancel} className='cancel-button' variant={cancelButtonType || "outline"} size={'lg'}
                                onClick={handleOnCancel}>{cancelButtonText || 'Cancelar'}</Button>
                      }
                      <Button loading={loading} className='confirm-button' variant={"info"} size={'lg'} disabled={disabledConfirm}
                              onClick={handleOnAccept}>{confirmButtonText || 'Aceptar'}</Button>
                    </Box>
                    }
                  </Box>
              </CardKolo>
            </Box>
            :
            <Box mx={0} className={clsx(classes.modalContentMobile, `modal-mobile a-very-fast ${open ? 'a-in-down':'a-out-down'}` )}
                 onClick={(e) => {
                   e.stopPropagation()
                 }}>
              <Box px={2.5} pt={3} pb={2.5} display={'flex'} justifyContent={'space-between'} className="kolo-border-bottom">
                <h6>{title}</h6>
                <img className="modal-close" height={18} width={18} src={IconClose} alt={'x'} onClick={onClose}/>
              </Box>

              <Box className="kolo-border-bottom">{children}</Box>

              <Box display={"flex"} justifyContent={`${centerActions ? 'center': 'flex-end'}`} width={1}>
                {
                  !hideActions &&
                  <Box mx={2} display={"flex"} flexDirection={"row"}>
                    {
                      showCancelButton &&
                      <Button loading={loadingCancel} className='cancel-button' variant={cancelButtonType || "outline"} size={'lg'}
                              onClick={handleOnCancel}>{cancelButtonText || 'Cancelar'}</Button>
                    }
                    <Button loading={loading} className='confirm-button' variant={"info"} size={'lg'} disabled={disabledConfirm}
                            onClick={handleOnAccept}>{confirmButtonText || 'Aceptar'}</Button>
                  </Box>
                }
              </Box>

            </Box>
        }
      </Fade>

    </Modal>
  )
};

export default ModalKolo;
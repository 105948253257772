import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'chartjs-plugin-style';
import 'chartjs-plugin-labels';

import {Alert, LinearProgressKolo, PrivateRoute, Splash} from './modules/shared/components';
import { selectRequesting } from './modules/shared/selectors';
import { userActions } from './modules/auth/actions';
import { userConstants } from './modules/auth/constants';
import { history } from './modules/shared/helpers';

import routes from './modules/routes';
import {Box} from "@material-ui/core";

const LoadingMessage = () => (
  <Box height={3}>
    {<LinearProgressKolo color={"#C14B8E"}/>}
  </Box>
);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
}));


export default function App() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const isRequestingUserInfo = useSelector(state => selectRequesting(state, [userConstants.REQUEST_USER_INFO]));

  useEffect(() => {
    dispatch(userActions.firebaseCheck())
  }, [])

  return (
    <div className={classes.root}>
      <Alert />
      {isRequestingUserInfo ?
        <Splash />
        :
        <Router history={history}>
          <Suspense fallback={<LoadingMessage />}>
            <Switch>
              {

                routes.map((route, index) => {
                  const { path, exact } = route;
                  const Component = route.component;

                  return (
                    route.private ?
                      <PrivateRoute key={index} {...route}></PrivateRoute>
                      :
                      <Route key={index} path={path} exact={exact} render={props => <Component {...props}></Component>} />
                  )
                })
              }
              <Redirect path="*" to="/"></Redirect>
            </Switch>
          </Suspense>
        </Router>
      }
    </div >
  );
}

import React, { useEffect, useState } from 'react';
import './TableKolo.scss';
import {Button, CardKolo, Input, LinearProgressKolo, Select} from "../index";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCellKolo from "../TableCellKolo";
import { makeStyles } from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
import { OptionsConstants } from "../../constants";
import IconFilters from "../../../../assets/icon-filters.svg";
import PaginationKolo from "../PaginationKolo";
import Empty from "../Empty";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  backWhite: {
    backgroundColor: '#FFFFFF'
  },
  root : { borderTopWidth: 1, borderColor: 'red',borderStyle: 'solid'} // or borderTop: '1px solid red'
});

const TableKolo = ({
                     total,
                     loading,
                     dataSource,
                     columns,
                     hideSearch,
                     getPagination,
                     handleChange
                  }) => {

  const [search, setSearch] = useState('');
  const [time, setTime] = useState(400)
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(pageSize);

  const isPhone = useMediaQuery('(max-width:480px)');

  const classes = useStyles();

  function filterItems(item) {
    if(getPagination) return true
    const exp = new RegExp(search.toUpperCase().trim(), 'g');
    return Object.keys(item).some(p => (`${JSON.stringify(item[p])}`).toUpperCase().match(exp));
  }

  useEffect(() => {
    if(time < 400){
      setTimeout(() => {
        if(time <= 0){
          setPage(1)
          handleChange(search)
          setTime(400)
        }
        else setTime(time - 100)
      }, 100)
    }
  }, [time])

  useEffect(() => {
    if(handleChange) {
      setTime(399)
    }
  }, [search])

  const onChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value));
    if(getPagination) getPagination(0, parseInt(event.target.value), search)
  };

  useEffect(() => {
    setLowerLimit(0);
    setUpperLimit(pageSize);
    setPage(1)
  }, [pageSize]);

  const onChangePage = (event, value) => {
    setPage(value);
    if(getPagination) getPagination((value-1) * pageSize, pageSize, search)
  };

  useEffect(() => {
    setLowerLimit((page - 1) * pageSize);
    setUpperLimit(((page - 1) * pageSize) + pageSize);
  }, [page]);

  const renderDataMovil = (columns, dataSource) => {
    let arrParentCol = [];
    let arrChildrenCol = [];
    columns.filter(f => !f.hideMobile).map(item => {
      if (arrChildrenCol.length <= 1) {
        arrChildrenCol.push(item)
        if (item.fillMobile){
          arrParentCol.push(arrChildrenCol);
          arrChildrenCol = [];
        }
      } else {
        arrParentCol.push(arrChildrenCol);
        arrChildrenCol = [];
        arrChildrenCol.push(item)
      }
    });

    if (arrChildrenCol.length > 0) {
      arrParentCol.push(arrChildrenCol)
    }

  return dataSource.filter(filterItems).slice(lowerLimit, upperLimit).map((itemDS, indexDS) =>
      <Box key={indexDS} px={2} pt={'13.5px'} pb={1.5} className="kolo-border-bottom">{
        arrParentCol.map((parentCol, index) =>
          <Box key={index} mb={0.5} display={"flex"} justifyContent={"space-between"}>
            {parentCol.map(childrenCol => <Box flex={1}>{childrenCol.renderMobile ? childrenCol.renderMobile(itemDS[childrenCol.key], itemDS, indexDS) : childrenCol.render ? childrenCol.render(itemDS[childrenCol.key], itemDS, indexDS) : itemDS[childrenCol.key]}</Box>)}
          </Box>
        )
      }</Box>
    )
  };

  return (
    <Box className="card-white a-in-opacity">
      <Box py={3} px={2} display={"flex"} flexDirection={isPhone ? "column" : "row"}>
        <Box mx={1} display={"flex"} flexDirection={"row"} flexGrow={1}>
          <Input className="table-ib-input" type={"search"} value={search}
                 onChange={event => setSearch(event.target.value)} block/>
          <Button className="table-ib-button"><img
            className="filter-img"
            src={IconFilters}
            alt={'filters'}/></Button>
        </Box>

        <Box mx={1} mt={{xs: 1, sm: 0}} ml={{xs: 'auto'}}>
          <Select className="table-select" options={OptionsConstants.optionsPages}
                  onChange={onChangePageSize}/>
        </Box>

      </Box>
      <Box height={3} className="kolo-border-bottom">
        {(loading) && <LinearProgressKolo/>}
      </Box>
      {
        isPhone ?
          <>
            {
              dataSource.length > 0 ?
                renderDataMovil(columns, dataSource)
              :
                <TableRow>
                  <TableCellKolo colSpan={columns.length}>
                    <Empty title={(loading || time < 400) && 'Cargando'}
                           subtitle={(loading || time < 400) && "Obteniendo datos, espera un momento..."}
                           subtitleSecond={!loading && "Intenta cambiar los parametros de busqueda."}/>
                  </TableCellKolo>
                </TableRow>
            }
          </>
          :
          <TableContainer className={classes.backWhite}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {
                    columns.map((item, index) =>
                      <TableCellKolo key={index} style={{
                        paddingLeft: index === 0 ? 40 : 12,
                        paddingRight: index + 1 === columns.length ? 40 : 12
                      }} align={item.align}>{item.title}</TableCellKolo>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dataSource.length > 0 ?
                    dataSource.filter(filterItems).slice(lowerLimit, upperLimit).map((itemDS, indexDS) =>
                      <TableRow key={indexDS}>
                        {
                          columns.map((itemC, indexC) =>
                            <TableCellKolo key={indexC} style={{
                              paddingLeft: indexC === 0 ? 40 : 12,
                              paddingRight: indexC + 1 === columns.length ? 40 : 12
                            }} align={itemC.align}
                                           className={itemC.className}>{itemC.render ? itemC.render(itemDS[itemC.key], itemDS, indexDS) : itemDS[itemC.key]}</TableCellKolo>
                          )
                        }
                      </TableRow>
                    )
                    :
                    <TableRow>
                      <TableCellKolo colSpan={columns.length}>
                        <Empty title={(loading || time < 400) && 'Cargando'}
                               subtitle={(loading || time < 400) && "Obteniendo datos, espera un momento..."}
                               subtitleSecond={!loading && "Intenta cambiar los parametros de busqueda."}/>
                      </TableCellKolo>
                    </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
      }
      <Box display={"flex"} justifyContent={"flex-end"}>
        <PaginationKolo page={page} count={Math.ceil((total || dataSource.filter(filterItems).length) / pageSize)} onChange={onChangePage}/>
      </Box>
    </Box>
  )
};

export default TableKolo;

import React, {useState} from 'react';
import "./OptionsCards.scss";
import Box from '@material-ui/core/Box';
import IconClose from "../../../../assets/icon-close.svg";

import {makeStyles, withStyles} from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));


const KoloRadio = withStyles({
  root: {
    color: '#AAADB3',
    '&$checked': {
      color: '#c34396',
    },
    marginRight: 8
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const OptionsCards = ({
                        title,
                        tabs,
                        children,
                        white,
                        buttons,
                        style,

                        options,

                        onChange
                      }) => {

  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('Choose wisely');

  const handleRadioChange = (value) => {
    setValue(value);
    // setHelperText(' ');
    // setError(false);
    //

    onChange(value)
    console.log('EVENT:::', value)
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (value === 'best') {
      setHelperText('You got it!');
      setError(false);
    } else if (value === 'worst') {
      setHelperText('Sorry, wrong answer!');
      setError(true);
    } else {
      setHelperText('Please select an option.');
      setError(true);
    }
  };

  return (
    <RadioGroup value={value} style={{flex: '1 1 auto'}}>
      {options && options.map((item, index)=>
        <Box key={index} mb={2.5} px={1} className="card-option" onClick={() => {
          // console.log('EVEddNT:::', item.value)

          handleRadioChange(item.value)
          // setValue(item.value)
        }}>
          <KoloRadio value={item.value}/>
          <h6 className="title-normal no-select" style={{minWidth: 150}}>{item.label}</h6>
          <Box minHeight={93} ml={2.5} px={2.5} py={2} display={"flex"} alignItems={"center"} className="kolo-border-left">
            <div className="text-normal no-select">{item.description}</div>
          </Box>
        </Box>
      )}
    </RadioGroup>
  )
};

export default OptionsCards;
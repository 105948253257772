import { koloProfileConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { company: {}, products: [] };

export function koloProfile(state = initialState, { type, payload, error }) {
  switch (type) {
    case koloProfileConstants.REQUEST_COMPANY_FINISHED:
      return {
        ...state,
        company: payload && payload.data || {},
      };
    case koloProfileConstants.REQUEST_KOLO_PRODUCTS_FINISHED:
      return {
        ...state,
        products: payload && payload.data || [],
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

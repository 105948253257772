import React from 'react';
// import {Spinner} from "react-bootstrap";
import "./Button.scss";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const VARIANT = [
  "primary",
  "danger",
  "outline"
];

const useStylesKolo = makeStyles((theme) => ({
  // root: {
  //   position: 'relative',
  // },
  // bottom: {
  //   color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  // },
  kolo: {
    color: 'white',
    marginLeft: 6,
    marginRight: 6
    // animationDuration: '550ms',
    // position: 'absolute',
    // left: 0,
  },
  // circle: {
  //   strokeLinecap: 'round',
  // },
}));

function KoloCircularProgress(props) {
  const classes = useStylesKolo();

  return (
    <div className={classes.root}>
      <CircularProgress
        // variant="indeterminate"
        // disableShrink
        className={classes.kolo}
        // classes={{
        //   circle: classes.circle,
        // }}
        size={22}
        // thickness={4}
        // {...props}
      />
    </div>
  );
}

const Button = ({
                         id,
                         children,
                         onClick,
                         variant,
                         size,
                         disabled,
                         style,
                         styleButton,
                         className,
                         loading,
                         img,
                         imgR,
                         block
                       }) => {

  const checkVariant = VARIANT.includes(variant) ? variant : VARIANT[0];

  return (
    <Box display="flex" flexDirection="column" flexGrow={block ? 1 : 0} position="relative" style={style}>
        <button
          id={id}
          disabled={disabled || loading}
          className={`button ${checkVariant} ${className}`}
          onClick={onClick} style={styleButton}>
          {loading &&
          <KoloCircularProgress/>
          }
          {img &&
          <img className="button-img" src={img} alt={''}/>
          }
          {children}
          {imgR &&
          <img className="button-img" src={imgR} alt={''}/>
          }
        </button>
    </Box>


    // <div className={`d-flex flex-column ${block ? 'flex-fill' : ''}`}>
    // <button id={id} disabled={disabled || loading} className={`button ${checkVariant} ${checkSize} ${className}`}
    //         onClick={onClick} type={type} style={style}>
    //   {loading &&
    //   <Spinner
    //     animation="border"
    //     size="sm"
    //     style={{marginRight: '6px', marginBottom: spinnerMarginBottom}}
    //   />
    //   }
    //   {img &&
    //   <img className="button-img" src={img} alt={''}/>
    //   }
    //   {children}
    // </button>
    // </div>
  )
};


export default Button;
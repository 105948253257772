import React from 'react';
import "./Select.scss";
import Box from "@material-ui/core/Box";

const Select = ({
                  title,
                  name,
                  options,
                  onChange,
                  className,
                  style
                }) => {

  return (
    <Box>
      {
        title &&
        <div className="select-title">{title}</div>
      }
      <select name={name} onChange={onChange} className={`select ${className}`} style={style}>
        {options.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
      </select>
    </Box>
  )
};

export default Select;
import { widgetsConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { sales: [], orders: [], consignedInventory: [], products: [] };

export function widgets(state = initialState, { type, payload, error }) {
  switch (type) {
    case widgetsConstants.REQUEST_ORDERS_FINISHED:
      return {
        ...state,
        orders: payload && payload.data,
      };
    case widgetsConstants.REQUEST_INVENTORY_FINISHED:
      return {
        ...state,
        consignedInventory: payload && payload.data || [],
      };
    case widgetsConstants.REQUEST_PRODUCTS_FINISHED:
      return {
        ...state,
        products: payload && payload.data,
      };
    case widgetsConstants.REQUEST_INVOICES_FINISHED:
      return {
        ...state,
        sales: payload && payload.data,
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}
import { notificationsConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = {
  recentNotifications: [],
  allNotifications: []
};

export function notifications(state = initialState, { type, payload, error }) {
  switch (type) {
    case notificationsConstants.REQUEST_RECENT_NOTIFICATIONS_FINISHED:
      return {
        ...state,
        recentNotifications: payload && payload.data || {}
      };
    case notificationsConstants.REQUEST_ALL_NOTIFICATIONS_FINISHED:
      return {
        ...state,
        allNotifications: payload && payload.data || {}
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

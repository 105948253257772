import React, {useEffect, useState} from 'react';
import "./ImagesGrid.scss";
import { ImageKolo } from "../index";
import { imagesActions } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@material-ui/core'

const ImagesGrid = ({
                      limit = 10,
                      disabled,
                      imagesType,
                      parentId,
                      companyId,
                      horizontal,
                      histories
                    }) => {

  const dispatch = useDispatch();
  const images = useSelector(state => histories ? state.images.histories : state.images.images);

  useEffect(() => {
    if (imagesType && parentId && companyId){
      if(imagesType.id !== 15) dispatch(imagesActions.getImages(imagesType, parentId, companyId))
      else dispatch(imagesActions.getHistories(imagesType, parentId, companyId))
    }
  }, [imagesType, parentId, companyId]);

  return (
    <Box className={`${horizontal ? 'ig-scroll' : ''}`}>
      <div
        className={`${horizontal ? 'images-container-horizontal' : 'images-container-vertical'} ${disabled ? 'images-container-disabled' : ''}`}>
        {
          images.concat(images.length < limit ? [{type: 'add'}] : []).map((item, index) =>
            <ImageKolo key={index} image={item} disabled={disabled} imagesType={imagesType} companyId={companyId}
                       parentId={parentId}
                       className={`${horizontal ? 'img-kh' : ''} ${horizontal && index > 0 ? 'img-kh-left' : ''}`}/>
          )
        }
      </div>
    </Box>
  )
};

export default ImagesGrid;
export const imagesConstants = {
  REQUEST_IMAGES: 'REQUEST_IMAGES',
  REQUEST_IMAGES_FINISHED: 'REQUEST_IMAGES_FINISHED',
  REQUEST_LOGO: 'REQUEST_LOGO',
  REQUEST_LOGO_FINISHED: 'REQUEST_LOGO_FINISHED',
  REQUEST_HISTORIES: 'REQUEST_HISTORIES',
  REQUEST_HISTORIES_FINISHED: 'REQUEST_HISTORIES_FINISHED',
  REQUEST_UPLOAD_IMAGES: 'REQUEST_UPLOAD_IMAGES',
  REQUEST_UPLOAD_IMAGES_FINISHED: 'REQUEST_UPLOAD_IMAGES_FINISHED',
  REQUEST_DELETE_IMAGES: 'REQUEST_DELETE_IMAGES',
  REQUEST_DELETE_IMAGES_FINISHED: 'REQUEST_DELETE_IMAGES_FINISHED',
};

export const imageTypes = {
  COMPANY:        {id: 1, path: '/companies'},
  PRODUCT:        {id: 2, path: '/products'},
  WAREHOUSE:      {id: 3, path: '/warehouses'},
  CLIENT:         {id: 4, path: '/clients'},
  DEPOSITS:       {id: 5, path: '/deposits'},
  INVOICES:       {id: 5, path: '/invoices'},
  OFFER:          {id: 6, path: '/offer'},
  LOGO:           {id: 7, path: '/logo'},
  CATEGORY:       {id: 8, path: '/categorization'},
  CATEGORY_BANNER:{id: 9, path: '/banner'},
  CATEGORY_ICON:  {id: 10, path: '/icon'},
  COMPANY_HISTORIES:  {id: 15, path: '/histories'}
};

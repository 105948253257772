import React from 'react';

const splashStyle = {
  background: 'linear-gradient(135deg, #216095, #216095, #6CAEC6)',
  height: '100vh',
  width: '100%',
  position: 'relative',
};

const spinnerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  marginLeft: '-40px',
  marginTop: '-40px'
};

const Splash = () => (
  <div style={splashStyle}>
    <div style={spinnerStyle} >
    </div>
  </div>
);

export default Splash;
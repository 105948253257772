import { userConstants } from 'modules/auth/constants';

const initialState = { [userConstants.REQUEST_USER_INFO]: true };

export const requesting = (state = initialState, action) => {
  const isRequestType = action.type.includes('REQUEST_');
  if (isRequestType === false) {
    return state;
  }
  const requestName = action.type.replace('_FINISHED', '');
  const isFinishedRequestType = action.type.includes('_FINISHED');

  return {
    ...state,
    [requestName]: isFinishedRequestType === false,
  };
};

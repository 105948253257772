import { userConstants } from '../constants';

const initialState = { authenticated: false, user: null, name: '', companies: [] };

export function authentication(state = initialState, { type, payload, error }) {
  switch (type) {
    case userConstants.REQUEST_LOGIN_FINISHED: {
      const authenticated = !error;
      let user = null;

      if (authenticated) {
        const { email, emailVerified, uid, xa, refreshToken } = payload.user;

        user = { email, emailVerified, uid, xa, refreshToken };
      }

      return {
        ...state,
        user,
        authenticated,
        error
      };
    }
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    case userConstants.REQUEST_USER_INFO_FINISHED:
      const data = payload && payload.data;

      return {
        ...state,
        name: data && data.name,
        error
      };
    case userConstants.REQUEST_FIREBASE_CHECK_FINISHED: {
      const authenticated = !!payload;
      let user = null;

      if (authenticated) {
        const { email, emailVerified, uid, xa, refreshToken } = payload;

        user = { email, emailVerified, uid, xa, refreshToken };
      }

      return {
        ...state,
        user,
        authenticated: payload !== null,
        error
      };
    }
    case userConstants.REQUEST_USER_COMPANIES_FINISHED:
      const companies =  payload && payload.data.filter(f => f.modules.filter(f => f.id === 15000).length > 0 ) || [];
      return {
        ...state,
        companies
      };
    default:
      return state
  }
}
import React, { useEffect } from 'react';
import './Alert.scss';
import AlertInfo from 'assets/alert-info.svg';
import AlertSuccess from 'assets/alert-success.svg';
import AlertWarning from 'assets/alert-warning.svg';
import AlertQuestion from 'assets/alert-question.svg';
import AlertError from 'assets/alert-error.svg';
import { alertActions } from '../../actions';
import { Button } from '../';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';

const Alert = ({
  confirmButtonColor,
  cancelButtonColor,
}) => {
  const {
    visible,
    title,
    subtitle,
    type,
    description,
    image,
    onAccept,
    onCancel,
    showCancelButton,
    cancelButtonText, confirmButtonText
  } = useSelector(state => state.alert);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [visible]);

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      window.removeEventListener('keydown', handleEsc);
      close(false)
    }
  };

  const close = () => {
    dispatch(alertActions.hideAlert())
  };

  const handleOnAccept = () => {
    if (onAccept) onAccept();
    close();
  };

  const handleOnCancel = () => {
    if (onCancel) onCancel();
    close();
  };

  const checkType = () => {
    switch (type) {
      case 'info':
        return {image: AlertInfo, title: '¡Información!'};
      case 'success':
        return {image: AlertSuccess, title: '¡Éxito!'};
      case 'warning':
        return {image: AlertWarning, title: '¡Advertencia!'};
      case 'question':
        return {image: AlertQuestion, title: '¡Pregunta!'};
      case 'error':
        return {image: AlertError, title: '¡Error!'};
      default:
        return {image: AlertInfo, title: '¡Información!'};
    }
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    visible &&
    <Box id='myAlert' style={{opacity: 1}} className='alert-modal a-in-opacity' onClick={close}>
      <Box className='alert-modal-content a-in-bounce' onClick={stopPropagation}>
        <img className='alert-image a-wobble no-select' src={image || checkType().image} alt={type}/>
        <span className='alert-title no-select'>{title || checkType().title}</span>
        {
          subtitle &&
          <span className='alert-subtitle'>{subtitle}</span>
        }
        {
          description &&
          <Box>
            <div className='divider'/>
            <div className='alert-description'>{description}</div>
          </Box>
        }
        <Box mt={3} display={"flex"} justifyContent={"center"} width={1} className="kolo-border-top">
          {
            showCancelButton &&
            <Button className='cancel-button' variant={"outline"} style={{backgroundColor: cancelButtonColor}} size={'lg'}
                    onClick={handleOnCancel}>{cancelButtonText || 'Cancelar'}</Button>
          }
          <Button className='confirm-button' variant={"info"} style={{backgroundColor: confirmButtonColor}} size={'lg'}
                  onClick={handleOnAccept}>{confirmButtonText || 'Aceptar'}</Button>
        </Box>
      </Box>
    </Box>
  )
};

export default Alert;
import { $http, firebase } from '../../../config';

function login(email, password) {
  return firebase.auth()
    .signInWithEmailAndPassword(email, password);
}

function logout() {
  return firebase.auth().signOut();
}

function firebaseCurrentUser() {
  return new Promise(resolve => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
        unsubscribe();
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    }
  })
}

async function checkUser(firebaseUser) {
  return firebaseUser && $http.get('/users/current')
}

function reset(email) {
  return firebase.auth()
    .sendPasswordResetEmail(email);
}

async function getCompanies() {
  return await $http.get(`/users/kolo/companies`);
}

export const userService = {
  login,
  logout,
  checkUser,
  firebaseCurrentUser,
  reset,
  getCompanies
};
import { alertConstants } from '../constants';

const initialState = {
    visible: false,
    onAccept: null,
    onCancel: null,
    title: '',
    subtitle: '',
    type: '',
    description: '',
    showCancelButton: false,
    cancelButtonText: 'Cancelar'
};

export const alert = (state = initialState, { type, payload }) => {
    switch (type) {
        case alertConstants.SHOW_ALERT:
            return {
                visible: true,
                ...payload
            };
        case alertConstants.HIDE_ALERT:
            return initialState;
        default:
            return state
    }
};
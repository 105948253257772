export const widgetsConstants = {
  REQUEST_SALES: 'REQUEST_SALES',
  REQUEST_SALES_FINISHED: 'REQUEST_SALES_FINISHED',
  REQUEST_ORDERS: 'REQUEST_ORDERS',
  REQUEST_ORDERS_FINISHED: 'REQUEST_ORDERS_FINISHED',
  REQUEST_INVENTORY: 'REQUEST_INVENTORY',
  REQUEST_INVENTORY_FINISHED: 'REQUEST_INVENTORY_FINISHED',
  REQUEST_PRODUCTS: 'REQUEST_PRODUCTS',
  REQUEST_PRODUCTS_FINISHED: 'REQUEST_PRODUCTS_FINISHED',
  REQUEST_INVOICES: 'REQUEST_INVOICES',
  REQUEST_INVOICES_FINISHED: 'REQUEST_INVOICES_FINISHED',
};
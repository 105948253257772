import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ProgressKolo = ({
                        color,
                        backgroundColor,
                        progress
                      }) => {

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: progress === 0 ? '#ffbaba' : theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color || '#C14B8E',
    },
  }))(LinearProgress);

  return (
    <BorderLinearProgress variant="determinate" value={progress} />
  )
};

export default ProgressKolo;
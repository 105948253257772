import { combineReducers } from '@reduxjs/toolkit';
import dashboardReducers from './dashboard/reducers';
import sharedReducers from './shared/reducers';
import authReducers from './auth/reducers';
import widgetsReducers from './widgets/reducers';
import productReducers from './product/reducers';
import balanceReducers from './balance/reducers';
import ordersReducers from './orders/reducers';
import koloProfile from './koloprofile/reducers';
import koloLinks from './kololinks/reducers';
import notifications from './notifications/reducers';

const rootReducer = combineReducers({
    ...authReducers,
    ...sharedReducers,
    ...dashboardReducers,
    ...widgetsReducers,
    ...productReducers,
    ...balanceReducers,
    ...ordersReducers,
    ...koloProfile,
    ...koloLinks,
    ...notifications,
});

export default rootReducer;

import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from "../../shared/actions";
import { companiesConstants } from "../../shared/constants";
import { history, createThunkEffect } from 'modules/shared/helpers';

export const userActions = {
  login,
  logout,
  firebaseCheck,
  userInfo,
  reset
};

function login(email, password) {
  return async dispatch => {
    const response = await createThunkEffect(dispatch, userConstants.REQUEST_LOGIN, userService.login, email, password);
    if (response.error){
      showAlert(dispatch, response.message || `Estamos en mantenimiento`, null, true)
    } else if (response) {
      console.log(history);
      dispatch(userInfo(response));
    }
  };
}

function firebaseCheck() {
  return async dispatch => {
    const response = await createThunkEffect(dispatch, userConstants.REQUEST_FIREBASE_CHECK, userService.firebaseCurrentUser);
    dispatch(userInfo(response));
  }
}

function userInfo(firebaseUser) {
  return async dispatch => {
    const response = await createThunkEffect(dispatch, userConstants.REQUEST_USER_INFO, userService.checkUser, firebaseUser);
    if (response) {
      if (response && response.error) {
        showAlert(dispatch, `Estamos en mantenimiento`, 'Por favor regresa mas tarde', true)
      } else {
        dispatch(getCompanies());
        history.push('/');
      }
    } else {
      dispatch(logout());
      history.replace("/login")
    }
  }
}

function logout() {
  return async dispatch => {
    window.localStorage.setItem(companiesConstants.SET_CURRENT_COMPANY, "");
    await createThunkEffect(dispatch, userConstants.REQUEST_LOGOUT, userService.logout);
    history.push('/login');
  }
}

function reset(email) {
  return async dispatch => {
    await createThunkEffect(dispatch, userConstants.REQUEST_RESET, userService.reset, email);
  };
}

function getCompanies() {
  return async dispatch => {
    await createThunkEffect(dispatch, userConstants.REQUEST_USER_COMPANIES, userService.getCompanies);
  }
}

function showAlert(dispatch, message, description, error) {
  const modalProps = {
    type: error ? 'error' : 'success',
    subtitle: message,
    description: description,
    onAccept: async () => {
      dispatch(alertActions.hideAlert());
      if (description){
        await createThunkEffect(dispatch, userConstants.REQUEST_LOGOUT, userService.logout);
        history.push('/login');
      }
    }
  };
  dispatch(alertActions.showAlert(modalProps));
}
import { imagesConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { images: [], logo: [], histories: [] };

export function images(state = initialState, { type, payload, error }) {
  switch (type) {
    case imagesConstants.REQUEST_IMAGES_FINISHED:
      return {
        ...state,
        images: (payload && payload.data) || [],
      };
    case imagesConstants.REQUEST_LOGO_FINISHED:
      return {
        ...state,
        logo: (payload && payload.data) || [],
      };
    case imagesConstants.REQUEST_HISTORIES_FINISHED:
      return {
        ...state,
        histories: (payload && payload.data) || [],
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

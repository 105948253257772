import React, {useState} from 'react';
import "./Input.scss";
import Box from '@material-ui/core/Box';
import IconEye from 'assets/icon-eye.svg'
import IconEyeHide from 'assets/icon-eye-hide.svg';
import IconSearch from 'assets/icon-search.svg';

const SIZES = [
  "sm",
  "md",
  "lg"
];

const Input = ({
                 id,
                 name,
                 type,
                 placeholder,
                 value,
                 onChange,
                 disabled,
                 size,
                 className,
                 style,
                 styleInput,
                 maxLength,
                 min,
                 max,
                 error,
                 valid,
                 title,
                 block,
                 pattern, nm
               }) => {

  const [showPassword, setShowPassword] = useState(false);

  const checkSize = SIZES.includes(size) ? size : SIZES[1];

  return (
    <Box display="flex" flexGrow={block ? 1 : 0} flexDirection="column" position="relative" style={style}>
      {title &&
      <div className={`${nm ? 'title-input-nm' : 'title-input'}`}>{title}</div>
      }
      <input
        id={id}
        name={name}
        type={type === "password" && showPassword ? "text" : type}
        placeholder={type === "search" && !placeholder ? "Buscar..." : placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`input ${type === "search" ? "search-input" : ""} ${checkSize} ${className} ${disabled ? 'input-disabled' : ''}`}
        pattern={pattern}
        maxLength={maxLength}
        min={min}
        max={max}
        style={{...styleInput}}
      />
      {type === "password" &&
      <img className="eye" src={showPassword ? IconEye : IconEyeHide} alt={'show'} onClick={() => setShowPassword(!showPassword)}/>
      }
      {(valid || error) &&
      <div className={`${valid && 'input-valid'} ${error && 'input-error'} `}>{valid || error}</div>}
    </Box>
  )
};

export default Input;

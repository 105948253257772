import React from 'react';
import "./QuantityPicker.scss";
import IconMinus from "../../../../assets/icon-minus.svg";
import IconPlus from "../../../../assets/icon-plus.svg";
import {Input} from "../index";

const VARIANT = [
  "dark",
  "light"
];

const QuantityPicker = ({
                          quantity,
                          setQuantity,
                          variant,
                          big,
                          className,
                          showInput,
                          lockUp,
                          lockDown
                        }) => {

  const checkVariant = VARIANT.includes(variant) ? variant : VARIANT[0];

  function downQuantity(event) {
    event.stopPropagation();
    if (!lockDown)
      setQuantity(quantity > 0 ? quantity - 1 : 0)
  }

  function upQuantity(event) {
    event.stopPropagation();
    if (!lockUp)
      setQuantity(quantity + 1)
  }

  function changeQuantity(event) {
    if (event.target.value >= 0)
      setQuantity(event.target.value)
  }

  return (
    <div className={`quantity-picker no-select quantity-${checkVariant}`}>
      {
        showInput ? (
          <Input value={quantity} type="number"
                 maxLength={7} style={{ width: 90 }}
                 onChange={changeQuantity}/>
        ) : (
          <>
            <div className={`button-quantity ${big ? 'button-big' : ''}`} onClick={downQuantity}>
              <img className="img-quantity" src={IconMinus} alt={"-"}/>
            </div>
            <div className={`product-quantity no-select ${big ? 'quantity-big' : ''} ${className}`}>
              {quantity}
            </div>
            <div className={`button-quantity ${big ? 'button-big' : ''}`} onClick={upQuantity}>
              <img className="img-quantity" src={IconPlus} alt={"+"}/>
            </div>
          </>
        )
      }
    </div>
  )
};

export default QuantityPicker;
export const ordersConstants = {
  REQUEST_GET_ORDERS: 'REQUEST_GET_ORDERS',
  REQUEST_GET_ORDERS_FINISHED: 'REQUEST_GET_ORDERS_FINISHED',
  REQUEST_GET_ALL_ORDERS: 'REQUEST_GET_ALL_ORDERS',
  REQUEST_GET_ALL_ORDERS_FINISHED: 'REQUEST_GET_ALL_ORDERS_FINISHED',
  REQUEST_GET_ALL_QUANTITIES: 'REQUEST_GET_ALL_QUANTITIES',
  REQUEST_GET_ALL_QUANTITIES_FINISHED: 'REQUEST_GET_ALL_QUANTITIES_FINISHED',
  REQUEST_GET_SINGLE_ORDER: 'REQUEST_GET_SINGLE_ORDER',
  REQUEST_GET_SINGLE_ORDER_FINISHED: 'REQUEST_GET_SINGLE_ORDER_FINISHED',
  REQUEST_POST_PROCESS_ORDER: 'REQUEST_POST_PROCESS_ORDER',
  REQUEST_POST_PROCESS_ORDER_FINISHED: 'REQUEST_POST_PROCESS_ORDER_FINISHED',
};

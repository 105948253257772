import React from 'react';
import "./AccordionKolo.scss";
import { Collapsible } from "../index";
import { Box } from "@material-ui/core";

const AccordionKolo = ({
                         panels,
                         classNameTitle,
                         defaultIndexExpanded
                       }) => {

  const [expanded, setExpanded] = React.useState('c-' + defaultIndexExpanded);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {
        panels.map((item, index) =>
          <Collapsible key={index} id={'c-' + index} expanded={expanded} handleChange={handleChange} title={item.title}
                       classNameTitle={`${classNameTitle} ${item.titleBlue && "title-collapsible-blue"}`} indent={item.indent}>
            <Box display={"flex"} flexGrow={1}>
              {item.panel}
            </Box>
          </Collapsible>
        )
      }
    </div>
  )
};

export default AccordionKolo;
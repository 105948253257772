import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const TableCellKolo = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#2F3542',
    whiteSpace: "pre-line",
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: '700',
    letterSpacing: 0,
    lineHeight: '17px',
    fontFamily: ['Montserrat'].join(','),
    borderColor: '#EAECF2',
    borderTopWidth: 1,
  },
  body: {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0,
    borderColor: '#EAECF2',
    fontFamily: ['Montserrat'].join(','),
  },
}))(TableCell);

export default TableCellKolo;
import { breadcrumbConstants } from '../constants';

const initialState = {
    items: [[]],
    last: []
};

export function breadcrumb(state = initialState, { type, payload, error }) {
    switch (type) {
        case breadcrumbConstants.REQUEST_UPDATE_BREADCRUMB:
            return {
                ...state,
                items: payload || [[]],
                error
            };
        case breadcrumbConstants.REQUEST_UPDATE_LAST:
            return {
                ...state,
                last: payload || [],
                error
            };
        default:
            return state
    }
}

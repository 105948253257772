export const koloProfileConstants = {
  REQUEST_COMPANY: 'REQUEST_COMPANY',
  REQUEST_COMPANY_FINISHED: 'REQUEST_COMPANY_FINISHED',
  REQUEST_EDIT_COMPANY: 'REQUEST_EDIT_COMPANY',
  REQUEST_EDIT_COMPANY_FINISHED: 'REQUEST_EDIT_COMPANY_FINISHED',
  REQUEST_KOLO_PRODUCTS: 'REQUEST_KOLO_PRODUCTS',
  REQUEST_KOLO_PRODUCTS_FINISHED: 'REQUEST_KOLO_PRODUCTS_FINISHED',
  REQUEST_ACTIVE_PRODUCT: 'REQUEST_ACTIVE_PRODUCT',
  REQUEST_ACTIVE_PRODUCT_FINISHED: 'REQUEST_ACTIVE_PRODUCT_FINISHED',
  REQUEST_FAVORITE: 'REQUEST_FAVORITE',
  REQUEST_FAVORITE_FINISHED: 'REQUEST_FAVORITE_FINISHED',
};

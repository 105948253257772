import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfigProd = {
  apiKey: "AIzaSyCNnfrejUaS_uPzB5hXi_nunxLnGpOZut8",
  authDomain: "cotizap-prod.firebaseapp.com",
  databaseURL: "https://cotizap-prod.firebaseio.com",
  projectId: "cotizap-prod",
  storageBucket: "cotizap-prod.appspot.com",
  messagingSenderId: "741320080115",
  appId: "1:741320080115:web:5ee39b031ecc1e38299586"
};

const firebaseConfigDev = {
  apiKey: "AIzaSyAQQ1ZJFogbud9XK88S4Gm2lYnVfImbD3M",
  authDomain: "distribution-app-dev.firebaseapp.com",
  databaseURL: "https://distribution-app-dev.firebaseio.com",
  projectId: "distribution-app-dev",
  storageBucket: "distribution-app-dev.appspot.com",
  messagingSenderId: "1058249941183",
  appId: "1:1058249941183:web:b15808bf4c4a4f00"
};

const firebaseQAConfig = {
  apiKey: "AIzaSyD_KX7M_NjsA0YyzIWVc3vjF3whjtUYOcQ",
  authDomain: "cotizap-qa.firebaseapp.com",
  databaseURL: "https://cotizap-qa.firebaseio.com",
  projectId: "cotizap-qa",
  storageBucket: "cotizap-qa.appspot.com",
  messagingSenderId: "72618988972",
  appId: "1:72618988972:web:92a8c0b1dd6098846c1609"
};

const { REACT_APP_ENVIRONMENT } = process.env;

switch (REACT_APP_ENVIRONMENT) {
  case 'production':
    firebase.initializeApp(firebaseConfigProd)
    break;
  case 'qa':
    firebase.initializeApp(firebaseQAConfig)
    break;
  default:
    firebase.initializeApp(firebaseConfigDev)
    break;
}

export default firebase;


export async function createThunkEffect(dispatch, actionType, effect, ...args) {
  dispatch(createAction(actionType));
  let model = null;

  try {
    
    model = await effect(...args);
    dispatch(createAction(`${actionType}_FINISHED`, model));

  } catch (error) {
    
    const { response, message } = error;
    model = { error: true, status: response && response.status, message, detail: response && response.data.message };

    dispatch(createAction(`${actionType}_FINISHED`, error, true));
  }
  return model;
}

export function createAction(type, payload = undefined, error = false, meta = null) {
  return { type, payload, error, meta };
}
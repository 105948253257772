export const productConstants = {
  REQUEST_PRODUCT: 'REQUEST_PRODUCT',
  REQUEST_PRODUCT_FINISHED: 'REQUEST_PRODUCT_FINISHED',
  REQUEST_PRODUCT_REPORT: 'REQUEST_PRODUCT_REPORT',
  REQUEST_PRODUCT_REPORT_FINISHED: 'REQUEST_PRODUCT_REPORT_FINISHED',
  REQUEST_EDIT_PRODUCT: 'REQUEST_EDIT_PRODUCT',
  REQUEST_EDIT_PRODUCT_FINISHED: 'REQUEST_EDIT_PRODUCT_FINISHED',
  REQUEST_UPDATE_INVENTORY: 'REQUEST_UPDATE_INVENTORY',
  REQUEST_UPDATE_INVENTORY_FINISHED: 'REQUEST_UPDATE_INVENTORY_FINISHED',
  REQUEST_GET_INVENTORY_BY_W_AND_P: 'REQUEST_GET_INVENTORY_BY_W_AND_P',
  REQUEST_GET_INVENTORY_BY_W_AND_P_FINISHED: 'REQUEST_GET_INVENTORY_BY_W_AND_P_FINISHED',
  REQUEST_GET_EXTERNAL: 'REQUEST_GET_EXTERNAL',
  REQUEST_GET_EXTERNAL_FINISHED: 'REQUEST_GET_EXTERNAL_FINISHED',
};

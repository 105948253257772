import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import './TabsKolo.scss';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#226095',
    },
    height: 3
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#525252',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    opacity: 0.5,
    letterSpacing: '-0.34px',
    fontFamily: ['Montserrat'].join(','),
    '&:hover': {
      color: '#525252',
      opacity: 1
    },
    '&:focus': {
      color: '#525252',
      opacity: 1
    },
  }
}))((props) => <Tab {...props} />);

const TabsKolo = ({
                    value,
                    tabs,
                    onChange,
                    variant,
                  }) => {
  return (
    <StyledTabs
      value={value}
      variant={variant}
      onChange={onChange}>
      {tabs.map((item, index) => <StyledTab key={index} label={ item.tooltip ?
        <LightTooltip title={item.tooltip} placement="top">
          <Box display={"flex"} justifyContent={"space-between"}>
            {item.quantity ? <div className="badge-tab">{item.quantity}</div> : <div/>}
            {item.label}
            {item.image && <img className="tabs-img" src={item.image} alt={''}/>}
          </Box>
        </LightTooltip> :
        <Box display={"flex"} justifyContent={"space-between"}>
          {item.quantity ? <div className="badge-tab">{item.quantity}</div> : <div/>}
          {item.label}
          {item.image && <img className="tabs-img" src={item.image} alt={''}/>}
        </Box>
      }/>)}
    </StyledTabs>
  )
};

export default TabsKolo;
import React  from 'react';
import "./Empty.scss";
import AlertWarning from '../../../../assets/alert-warning.svg';

const Empty = ({
                      icon,
                      title,
                      subtitle,
                      subtitleSecond,
                      className
                    }) => {

  return (
    <div className={`card-white card-empty ${className}`}>
      <img className="empty-image" src={icon || AlertWarning}/>
      <div className="empty-title">{title || '¡Lo sentimos!'}</div>
      <div className="empty-subtitle">{subtitle || 'No hay información para mostrar.'}</div>
      {subtitleSecond &&
      <div
        className="empty-subtitle">{subtitleSecond}</div>
      }
    </div>
  )
};

export default Empty;
import { $http } from '../../../config';
import firebase from "firebase";
import uuid from 'uuid-random';

async function getImages(imageTypeId, parentId, companyId) {
  return $http.get(`/images/upload?imageTypeId=${imageTypeId}&parentId=${parentId}&companyId=${companyId}`)
}

async function uploadImages(imageTypeId, parentId, url, name, description) {
return $http.post('/images/upload/single', {imageTypeId, parentId, url, name, description});
}

async function deleteImages(id, imageTypeId, parentId) {
  return $http.delete(`images/upload/${id}`, {params: {parentId, imageTypeId}});
}

export const imagesServices = {
  getImages,
  uploadImages,
  deleteImages,
};

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from 'modules/reducers';
import logger from 'redux-logger'


const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
  }), logger]
})

export default store;
import React, {useState} from 'react';
import './DatePickerKolo.scss';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { ThemeProvider } from "@material-ui/core/styles";
import {createMuiTheme, Box, useMediaQuery} from "@material-ui/core";

esLocale.options.weekStartsOn = 0

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      marginNormal: {
        marginTop: 0
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#C14B8E',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#545459',
      },
      daySelected: {
        backgroundColor: '#C14B8E',
        '&:hover': {
          backgroundColor: 'rgba(194, 76, 143, 0.85)'
        },
      },
      dayDisabled: {
        color: 'rgba(84, 84, 89, 0.4)',
      },
      current: {
        color: '#C14B8E',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'rgba(194, 76, 143, 0.5)',
      },
    },
    MuiInputBase: {
      root: {
        outline: 'none',
        fontFamily: 'Montserrat',
        fontSize: 14,
        color: '#545459',
        backgroundColor: 'white'
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#C14B8E'
      }
    },
    MuiButton: {
      label: {
        color: '#545459',
      }
    },
  },
});

const DatePickerKolo = ({
                          label,
                          value,
                          onChange,
                          disableFuture,
                          disablePast,
                          minDate,
                          maxDate,
                          disabled,
                          disablePrevDates
                        }) => {

  const isPhone = useMediaQuery('(max-width:480px)');

  function disablePreviousDates() {
    const startDate = new Date();
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }

  return (
    <Box>
      <div className="dp-title">{label}</div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            margin="normal"
            format={isPhone ? "dd/MM/yy" : "dd/MM/yyyy"}
            value={value}
            disabled={disabled}
            onChange={onChange}
            minDateMessage="La fecha no debe ser anterior a la fecha mínima"
            maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
            invalidDateMessage="Formato de fecha no válido"
            inputVariant="outlined"
            size="small"
            disableFuture={disableFuture}
            disablePast={disablePast}
            okLabel="Aceptar"
            clearLabel="Limpiar"
            cancelLabel="Cancelar"
            className="dp-border"
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={disablePrevDates ? disablePreviousDates() : null}/>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Box>
  )
};

export default DatePickerKolo;

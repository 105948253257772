import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const LinearProgressKolo = ({
                              color
                            }) => {

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 3,
    },
    colorPrimary: {
      backgroundColor: '#FFFFFF',
    },
    bar: {
      backgroundColor: color || '#C14B8E',
    },
  }))(LinearProgress);

  return (
    <BorderLinearProgress />
  )
};

export default LinearProgressKolo;
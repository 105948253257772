import { balanceConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { balance: [], banks: [], accounts: [], entryTypes: [], currentReference: {}, disbursementActive: {} };

export function balance(state = initialState, { type, payload, error }) {
  switch (type) {
    case balanceConstants.REQUEST_BALANCE_FINISHED:
      return {
        ...state,
        balance: payload && payload.data || [],
      };
    case balanceConstants.REQUEST_BANKS_FINISHED:
      return {
        ...state,
        banks: payload && payload.data || [],
      };
    case balanceConstants.REQUEST_ACCOUNTS_FINISHED:
      return {
        ...state,
        accounts: payload && payload.data || [],
      };
    case balanceConstants.REQUEST_BALANCE_ENTRY_TYPES_FINISHED:
      return {
        ...state,
        entryTypes: payload && payload.data || [],
      };
    case balanceConstants.REQUEST_GET_INFO_REFERENCE_FINISHED:
      return {
        ...state,
        currentReference: payload && payload.data || {},
      };
    case balanceConstants.REQUEST_GET_ACTIVE_DISBURSEMENT_FINISHED:
      return {
        ...state,
        disbursementActive: payload && payload.data || {},
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}
import React, {useState} from 'react';
import "./CardKolo.scss";
import Box from '@material-ui/core/Box';
import IconClose from "../../../../assets/icon-close.svg";

const CardKolo = ({
                    title,
                    tabs,
                    children,
                    white,
                    buttons,
                    style,
                    centerTitle
                  }) => {

  return (
    <Box className="card-white" flexGrow={1} style={style}>
      <Box className={`card-head ${white ? 'ck-white' : ''} ${centerTitle ? 'ct-center' : ''}`} px={2.5}>
        <div className="tabs-container">
          {tabs ?
            tabs
            :
            <span className={`card-title ${white ? 'ct-dark' : ''}`}>{title}</span>
          }
        </div>
        <div>
          {buttons}
        </div>
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  )
};

export default CardKolo;
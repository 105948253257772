import { alertConstants } from '../constants';

export const alertActions = {
    showAlert,
    hideAlert
};

function showAlert({ message, title, subtitle, type, description, image, onAccept, onCancel, showCancelButton, cancelButtonText, confirmButtonText }) {
    return {
        type: alertConstants.SHOW_ALERT,
        payload: {
            message,
            title,
            subtitle,
            type,
            description,
            image,
            onAccept,
            onCancel,
            showCancelButton,
            cancelButtonText,
            confirmButtonText
        }
    };
}

function hideAlert() {
    return { type: alertConstants.HIDE_ALERT, payload: null };
}
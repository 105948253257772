export const userConstants = {
  REQUEST_LOGIN: 'REQUEST_LOGIN',
  REQUEST_LOGIN_FINISHED: 'REQUEST_LOGIN_FINISHED',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  REQUEST_LOGOUT_FINISHED: 'REQUEST_LOGOUT_FINISHED',
  REQUEST_FIREBASE_CHECK: 'REQUEST_FIREBASE_CHECK',
  REQUEST_FIREBASE_CHECK_FINISHED: 'REQUEST_FIREBASE_CHECK_FINISHED',
  REQUEST_USER_INFO: 'REQUEST_USER_INFO',
  REQUEST_USER_INFO_FINISHED: 'REQUEST_USER_INFO_FINISHED',
  REQUEST_RESET: 'REQUEST_RESET',
  REQUEST_RESET_FINISHED: 'REQUEST_RESET_FINISHED',
  REQUEST_USER_COMPANIES: 'REQUEST_USER_COMPANIES',
  REQUEST_USER_COMPANIES_FINISHED: 'REQUEST_USER_COMPANIES_FINISHED',
};
import { companiesConstants } from '../constants';
import {$http} from "../../../config";
import {koloLinksConstants} from "../../kololinks/constants";

export const companiesActions = {
  setCurrentCompany
};

function setCurrentCompany(company) {
  return dispatch =>{
    window.localStorage.setItem(companiesConstants.SET_CURRENT_COMPANY, JSON.stringify(company));
    dispatch({type: companiesConstants.SET_CURRENT_COMPANY, payload: company});
  }
}

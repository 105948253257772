import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
import './PaginationKolo.scss'

const PaginationKolo = ({
                          count,
                          page,
                          onChange
                        }) => {

  return (
    <Box p={2.5}>
      <Pagination className="pagination-kolo no-select" count={count} shape="rounded" page={page} onChange={onChange} />
    </Box>
  )
};

export default PaginationKolo;
import axios from 'axios';
import store from './store';
import { BASE_URL } from './app-config';
import firebase from './firebase';

const $http = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

$http.interceptors.request.use(
  async config => {

    const { modules, companies } = store.getState();
    const token = (await firebase.auth().currentUser.getIdTokenResult()).token;
    const company = companies.currentCompany.id;
    const module = modules.rootModule.id;

    const headers = { Authorization: 'Bearer ' + token, 'COTIZAP-Company': company, 'COTIZAP-Module':  module };
    config.headers = headers;

    return config
  }, (error) => {
    console.error(error);
    return Promise.reject(error)
  });

export default $http;

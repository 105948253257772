import { $http } from '../../../config';
import {modulesConstants} from '../constants';

export const modulesActions = {
  setRootModule
};

function setRootModule(module) {
  return dispatch =>{
    window.localStorage.setItem(modulesConstants.SET_ROOT_MODULE, JSON.stringify(module));
    dispatch({type: modulesConstants.SET_ROOT_MODULE, payload: module});
  };
}

import { koloLinksConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { links: [], currentLink: {} };

export function kololinks(state = initialState, { type, payload, error }) {
  switch (type) {
    case koloLinksConstants.REQUEST_LINKS_FINISHED:
      return {
        ...state,
        links: payload && payload.data,
      };
    case koloLinksConstants.SET_CURRENT_LINK:
      return {
        ...state,
        currentLink: payload,
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}
import { productConstants } from '../constants';
import {userConstants} from "../../auth/constants";

const initialState = { product: {}, productGraph: {}, productInventory: null , existences: []};

export function product(state = initialState, { type, payload }) {
  switch (type) {
    case productConstants.REQUEST_PRODUCT_FINISHED:
      return {
        ...state,
        product: (payload && payload.data) || {},
      };
    case productConstants.REQUEST_GET_EXTERNAL_FINISHED:
      return {
        ...state,
        existences: (payload && payload.data) || []
      }
    case productConstants.REQUEST_PRODUCT_REPORT_FINISHED:
      return {
        ...state,
        productGraph: (payload && payload.data) || {},
      };
    case productConstants.REQUEST_GET_INVENTORY_BY_W_AND_P_FINISHED:
      return {
        ...state,
        productInventory: (payload && payload.data) || null,
      };
    case userConstants.REQUEST_LOGOUT_FINISHED:
      return initialState;
    default:
      return state
  }
}

import React from 'react';
import "./Collapsible.scss";

import Box from '@material-ui/core/Box';
import clsx from 'clsx';


import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDown from "../../../../assets/arrow-down-blue.svg";

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid #EAECF2',
    minHeight: 42,
    '&$expanded': {
      minHeight: 42,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #EAECF2',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(2),
  }
}));

const Collapsible = ({
                       title,
                       children,
                       classNameTitle,
                       id,
                       defaultExpanded,
                       expanded,
                       handleChange,
                       indent
                     }) => {

  const [localExpanded, setLocalExpanded] = React.useState(defaultExpanded ? "acc1" : "");

  const handleLocalChange = (panel) => (event, newExpanded) => {
    setLocalExpanded(newExpanded ? panel : false);
  };

  const classes = useStyles();

  return (
    <Box className={clsx(indent && classes.marginLeft, "a-in-opacity") }>
      <Accordion square expanded={expanded ? (expanded === id) : localExpanded === "acc1"} onChange={handleChange ? handleChange(id) : handleLocalChange("acc1")}>
        <AccordionSummary expandIcon={<img alt="" src={ArrowDown}/>}>
          <span className={`collapsible-text ${classNameTitle}`}>{title}</span>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>

  )
};

export default Collapsible;
import { imagesConstants } from '../constants';
import { imagesServices } from '../services';
import { createThunkEffect } from 'modules/shared/helpers';
import uuid from "uuid-random";
import firebase from '../../../config/firebase';
import {alertActions} from "./alert.actions";

export const imagesActions = {
  getImages,
  getLogo,
  getHistories,
  uploadImages,
  deleteImages,
};

function getImages(imageType, parentId, companyId) {
  return async dispatch => {
    console.log(imageType, parentId, companyId)
    await createThunkEffect(dispatch, imagesConstants.REQUEST_IMAGES, imagesServices.getImages, imageType.id, parentId, companyId);
  }
}

function getLogo(imageType, parentId, companyId) {
  return async dispatch => {
    await createThunkEffect(dispatch, imagesConstants.REQUEST_LOGO, imagesServices.getImages, imageType.id, parentId, companyId);
  }
}

function getHistories(imageType, parentId, companyId) {
  return async dispatch => {
    await createThunkEffect(dispatch, imagesConstants.REQUEST_HISTORIES, imagesServices.getImages, imageType.id, parentId, companyId);
  }
}

function uploadImages(file, imageType, entityId, onProgress, onSuccess) {
  return async dispatch => {
    const {name} = file;
    const extension = name.split('.').reverse()[0];
    const randomName = `${imageType.path}/${uuid()}.${extension}`;

    const storage = firebase.storage();
    const ref = storage.ref().child(randomName);
    const uploadTask = ref.put(file);

    uploadTask.on('state_changed',
      snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('pppp',progress)
        if (onProgress) onProgress(progress);
      },
      error => {
        console.log(error)
      },
      async () => {
        const url = await uploadTask.snapshot.ref.getDownloadURL();
        if (entityId) {
          const response = await createThunkEffect(dispatch, imagesConstants.REQUEST_UPLOAD_IMAGES, imagesServices.uploadImages, imageType.id, entityId, url, randomName, null);
        }
        if(onSuccess) onSuccess()
      }
    )
  }
}

function deleteImages(id, name, imageType, entityId, onProgress, onSuccess){
  return async dispatch => {
    try {
      const modalProps = {
        type: 'question',
        title: '¿Desea eliminar?',
        showCancelButton: true,
        onAccept: async () => {
          const storage = firebase.storage();
          const ref = storage.ref().child(name);
          if(onProgress) onProgress()
          await  ref.delete();
          const response = await createThunkEffect(dispatch, imagesConstants.REQUEST_DELETE_IMAGES, imagesServices.deleteImages, id, imageType.id, entityId);
          if(onSuccess) onSuccess()
        },
      };
      dispatch(alertActions.showAlert(modalProps));
    } catch (error) {
      console.log(error);
    }
  }
}

import moment from "moment";

const GuatemalaTimeZoneOffset = 6;

export const formatNumberWithCommas = x => (x !== null || !typeof x !== undefined) ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
export const formatDateFromMillis = (millis) => {
  const day = moment(millis).utc(true).utcOffset(GuatemalaTimeZoneOffset);
  return moment(day).format('DD/MM/YYYY hh:mm a');
};

export const formatDate = (millis) => {
  return moment(millis).format('DD/MM/YYYY');
};

export const formatDateLarge = (millis) => {
  return millis ? moment(millis).format('DD / MM / YYYY - hh:mm a') : '';
};

export const encodeEmoji = (text = "") => {
  let chars = text.split(""), prev = -1, unicode = '', result = '';
  chars.map(value => {
    if(value.charCodeAt(0) > 255  && prev <0) prev = value;
    else if(value.charCodeAt(0) > 255){
      unicode = (prev+value).codePointAt(0).toString(16);
      result += "/*"+unicode+"*/";
      prev = -1
    }
    else result +=value
  });
  return result
};

export const decodeEmoji = (text = "") => {
  const exp = new RegExp((/\/\*[a-z|A-Z|0-9]*\*\//));
  let needChange = true;
  while (needChange){
    if((`${text}`).match(exp)){
      let emo = String.fromCodePoint("0x"+(`${text}`).match(exp)[0].replace("/*","").replace("*/",""));
      text = text.replace((`${text}`).match(exp)[0], emo);
      needChange = true
    }
    else needChange = false
  }
  return text
};
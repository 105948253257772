export const balanceConstants = {
  REQUEST_BALANCE: 'REQUEST_BALANCE',
  REQUEST_BALANCE_FINISHED: 'REQUEST_BALANCE_FINISHED',
  REQUEST_BANKS: 'REQUEST_BANKS',
  REQUEST_BANKS_FINISHED: 'REQUEST_BANKS_FINISHED',
  REQUEST_ACCOUNTS: 'REQUEST_ACCOUNTS',
  REQUEST_ACCOUNTS_FINISHED: 'REQUEST_ACCOUNTS_FINISHED',
  REQUEST_CREATE_DISBURSEMENT: 'REQUEST_CREATE_DISBURSEMENT',
  REQUEST_CREATE_DISBURSEMENT_FINISHED: 'REQUEST_CREATE_DISBURSEMENT_FINISHED',
  REQUEST_BALANCE_ENTRY_TYPES: 'REQUEST_BALANCE_ENTRY_TYPES',
  REQUEST_BALANCE_ENTRY_TYPES_FINISHED: 'REQUEST_BALANCE_ENTRY_TYPES_FINISHED',
  REQUEST_GET_INFO_REFERENCE: 'REQUEST_GET_INFO_REFERENCE',
  REQUEST_GET_INFO_REFERENCE_FINISHED: 'REQUEST_GET_INFO_REFERENCE_FINISHED',
  REQUEST_GET_ACTIVE_DISBURSEMENT: 'REQUEST_GET_ACTIVE_DISBURSEMENT',
  REQUEST_GET_ACTIVE_DISBURSEMENT_FINISHED: 'REQUEST_GET_ACTIVE_DISBURSEMENT_FINISHED',
};